import * as React from 'react'
import styled from 'styled-components'

import Score from './score'
import { tablet } from 'shared/media-queries'

const Wrapper = styled.div`
	flex: 0 0 400px;
	${tablet} {
		flex: 1 1 0%;
	}
`
const Title = styled.div`
	max-width: 100%;
	font-size: 54px;
	font-weight: 700;
	line-height: 1.1;
	margin: 0;
	${tablet} {
		font-size: 36px;
	}
`
const SectionTitle = styled.div`
	font-size: 24px;
	color: white;
	margin: 25px 0 15px;
`
const PostText = styled.div`
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 14px;
	font-weight: 600;
	margin: 40px 0 20px;
`

// prettier-ignore
interface GraphsInterface {
	title?: string;
	postText?: string;
	preconScore: 'A' | 'B' | 'C' | 'F';
	siteconScore: 'A' | 'B' | 'C' | 'F';
}
const Graphs: React.FC<GraphsInterface> = ({
	title,
	preconScore,
	siteconScore,
	postText,
}) => (
	<Wrapper>
		<Title>{title}</Title>
		<SectionTitle>Preconstruction Risk Score</SectionTitle>
		<Score score={preconScore} />
		<SectionTitle>Site Construction Risk Score</SectionTitle>
		<Score score={siteconScore} />
		{postText && <PostText>{postText}</PostText>}
	</Wrapper>
)

export default Graphs
