import * as React from 'react'
import styled from 'styled-components'

import { ContentWrapper, Button } from 'views/components'
import { showRiskReportModal } from 'components/modal-manager'

const Wrapper = styled.div`
	color: white;
	text-align: center;
`
const Header = styled.div`
	font-size: 44px;
	padding-top: 100px;
	font-weight: 700;
	line-height: 1.1;
`
const Text = styled.div`
	font-size: 24px;
	padding: 20px 0;
`
const CTAButton = styled(Button)`
	padding: 0 60px;
`

const RiskScoreMobileView = () => (
	<ContentWrapper>
		<Wrapper>
			<Header>Download the 2020 U.S. Construction Risk Outlook</Header>
			<Text>
				Enter your email address, and we'll send you a PDF copy of the report.
			</Text>
			<CTAButton label="Get it now" onClick={showRiskReportModal} />
		</Wrapper>
	</ContentWrapper>
)

export default RiskScoreMobileView
