import * as React from 'react'
import styled from 'styled-components'

import { navy, gray20 } from 'shared/colors'

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`
const FabulousGraph = styled.div`
	width: 100%;
	height: 10px;
	background-image: linear-gradient(
		to right,
		#ff6549 25%,
		#ffa33e 50%,
		#3dcec6 75%,
		#32ade6 100%
	);
`

const transitionSpeed = 500
const getTransitionCSS = score => {
	if (score === 'A') return `right: 5%;`
	if (score === 'B') return `right: 30%;`
	if (score === 'C') return `right: 60%;`
	if (score === 'F') return `right: 85%;`
	return null
}
const LetterScore = styled.div`
	position: absolute;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	color: ${navy};
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: 600;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
	border: 1px solid ${gray20};
	top: -15px;

	will-change: right;
	${(props: { score: string }) => getTransitionCSS(props.score)}
	transition: right ${transitionSpeed}ms;
`

interface ScoreInterface {
	score: 'A' | 'B' | 'C' | 'F'
}
const Score: React.FC<ScoreInterface> = ({ score }) => (
	<Wrapper>
		<FabulousGraph />
		<LetterScore score={score}>{score}</LetterScore>
	</Wrapper>
)

export default Score
