export default [
	{
		question: `How are the state and regional scores determined?`,
		answer: `When conducting our analysis, we looked at several key indicators, such as labor force projections, construction spending trends, and annual incident reports. For many indicators, we manipulated the raw data to represent a comparable metric across all 50 states. We then ranked the 50 states and assigned points based on their position. These points were added up to represent total scores for preconstruction and site construction.`,
	},
	{
		question: `Why preconstruction and site construction?`,
		answer: `To easily understand the potential risks affecting general contractors and owners in 2020, we assigned a rating to the different construction phases.`,
	},
	{
		question: `How should I use this report?`,
		answer: `We hope general contractors and owners use this report as one of the many resources available to them when evaluating whether to take on projects in certain areas. Once projects are chosen, we also see this report as a tool for risk and estimation teams to better prepare for risks in certain states and regions. For example, understanding the labor issues or inclement weather you may encounter during a project can help inform the mitigation tactics you put in place.`,
	},
	{
		question: `How did you address population bias?`,
		answer: `When analyzing raw data, some states can skew the results due to their size. States like New York and California always spend more on construction than smaller states like Rhode Island or more sparsely populated states like Wyoming. We used different techniques to account for these outliers, ensuring all states were evaluated evenly. For instance, when analyzing construction spending, we compared the annual percent change over 10 years for each state rather than the total amount of spending.`,
	},
]
