import * as React from 'react'
import styled from 'styled-components'

import { grayText, navy } from 'shared/colors'
import { statesToRegions, regionsWording } from '../constants'
import { Button } from 'views/components'
import Image from './image'
import { showRiskReportModal } from 'components/modal-manager'
import { laptop, tablet } from 'shared/media-queries'

const Wrapper = styled.div`
	background-color: white;
	width: 100%;
	padding: 40px 100px;
	${laptop} {
		padding: 40px;
	}
	${tablet} {
		padding: 20px;
	}
`
const Header = styled.div`
	color: ${navy};
	font-weight: 600;
	margin-bottom: 20px;
`
const Text = styled.div`
	font-size: 18px;
	color: ${grayText};
	column-count: 2;
`
const BannerAd = styled.div`
	width: 100%;
	background-color: ${navy};
	height: 125px;
	margin-top: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
	${tablet} {
		height: 150px;
	}
`
const BannerText = styled.div`
	font-weight: 600;
`
const ImageContainer = styled.div`
	flex: 0 0 160px;
	height: 175px;
`
const ButtonWrapper = styled.div`
	flex: 0 0 210px;
	text-align: right;
	${tablet} {
		text-align: left;
		margin-top: 10px;
	}
`
const CTAButton = styled(Button)`
	padding: 0 60px;
`
const TextAndButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	${tablet} {
		display: block;
	}
`

const Summary = ({ state }) => {
	const region = statesToRegions[state]
	const regionWording = regionsWording[region]

	return (
		<Wrapper>
			<Header>Summary</Header>
			<Text>{regionWording}</Text>
			<BannerAd>
				<ImageContainer>
					<Image />
				</ImageContainer>
				<TextAndButtonWrapper>
					<BannerText>
						Download the full report to get more in-depth insights into state,
						regional, and national risk trends for 2020.
					</BannerText>
					<ButtonWrapper>
						<CTAButton label="Get it now" onClick={showRiskReportModal} />
					</ButtonWrapper>
				</TextAndButtonWrapper>
			</BannerAd>
		</Wrapper>
	)
}

export default Summary
