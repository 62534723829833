import * as React from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import backgroundImage from './background-image'
import { navy } from 'shared/colors'
import { Spacer, ContentWrapper } from 'views/components'
import { tablet, mobile } from 'shared/media-queries'
import StateSelect from './state-select'
import ResultsSection from './results-section'
import Summary from './summary'
import MobileView from './mobile-view'

const Wrapper = styled.div`
	min-height: 740px;
	background-color: ${navy};
	${tablet} {
		min-height: 550px;
	}
	${mobile} {
		min-height: auto;
	}
`
const DesktopWrapper = styled.div`
	min-height: 740px;
	display: flex;
	align-items: center;
	flex-direction: column;
	color: white;
	${tablet} {
		min-height: 550px;
	}
	${mobile} {
		display: none;
	}
`
const MobileWrapper = styled.div`
	display: none;
	${mobile} {
		display: block;
	}
`
const BackgroundImage = styled.div`
	background: url(${backgroundImage}) no-repeat top center;
	background-size: 100%;
	padding-bottom: 75px;
`
const TopSpacer = styled.div`
	flex: 0 0 250px;
	${tablet} {
		flex: 0 0 200px;
	}
`
const Header = styled.div`
	font-size: 54px;
	max-width: 100%;
	font-weight: 700;
	line-height: 1.1;
`
const Text = styled.div`
	font-size: 22px;
`
const ResultsSpacer = styled.div`
	flex: 0 0 80px;
`
const transitionSpeed = 500
const TransitionContentWrapper = styled(ContentWrapper)`
	will-change: opacity, transform;
	&.risk-section-transition-enter {
		opacity: 0;
		transform: translateY(-10%);
	}
	&.risk-section-transition-enter-active {
		opacity: 1;
		transition: all ${transitionSpeed}ms;
		transform: translateY(0%);
	}
	&.risk-section-transition-exit {
		opacity: 1;
		transform: translateY(0%);
	}
	&.risk-section-transition-exit-active {
		opacity: 0;
		transition: all ${transitionSpeed}ms;
		transform: translateY(-10%);
	}
`

const RiskScore: React.FC<{
	forwardedRef: React.RefObject<HTMLInputElement>
}> = ({ forwardedRef }) => {
	const [selectedState, setSelectedState] = React.useState('')

	return (
		<Wrapper ref={forwardedRef}>
			<BackgroundImage>
				<DesktopWrapper>
					<TopSpacer />
					<Header>See your state’s risk score.</Header>
					<Spacer size={10} />
					<Text>Uncover the biggest risks impacting your state in 2020.</Text>
					<StateSelect onChange={({ value }) => setSelectedState(value)} />
					<ResultsSpacer />
					<CSSTransition
						in={!!selectedState}
						timeout={transitionSpeed}
						classNames="risk-section-transition"
					>
						<TransitionContentWrapper>
							{selectedState && (
								<>
									<ResultsSection state={selectedState} />
									<Summary state={selectedState} />
								</>
							)}
						</TransitionContentWrapper>
					</CSSTransition>
				</DesktopWrapper>
				<MobileWrapper>
					<MobileView />
				</MobileWrapper>
			</BackgroundImage>
		</Wrapper>
	)
}

export default RiskScore
