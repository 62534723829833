export const statesToRegions = {
	Alabama: 'Southeast',
	Alaska: 'Alaska',
	Arizona: 'Western',
	Arkansas: 'Southern',
	California: 'Western',
	Colorado: 'High Plains',
	Connecticut: 'Northeast',
	Delaware: 'Northeast',
	Florida: 'Southeast',
	Georgia: 'Southeast',
	Hawaii: 'Hawaii',
	Idaho: 'Western',
	Illinois: 'Midwest',
	Indiana: 'Midwest',
	Iowa: 'Midwest',
	Kansas: 'High Plains',
	Kentucky: 'Midwest',
	Louisiana: 'Southern',
	Maine: 'Northeast',
	Maryland: 'Northeast',
	Massachusetts: 'Northeast',
	Michigan: 'Midwest',
	Minnesota: 'Midwest',
	Mississippi: 'Southern',
	Missouri: 'Midwest',
	Montana: 'High Plains',
	Nebraska: 'High Plains',
	Nevada: 'Western',
	'New Hampshire': 'Northeast',
	'New Jersey': 'Northeast',
	'New Mexico': 'Southern',
	'New York': 'Northeast',
	'North Carolina': 'Southeast',
	'North Dakota': 'High Plains',
	Ohio: 'Midwest',
	Oklahoma: 'Southern',
	Oregon: 'Western',
	Pennsylvania: 'Northeast',
	'Rhode Island': 'Northeast',
	'South Carolina': 'Southeast',
	'South Dakota': 'High Plains',
	Tennessee: 'Southern',
	Texas: 'Southern',
	Utah: 'Western',
	Vermont: 'Northeast',
	Virginia: 'Southeast',
	Washington: 'Western',
	'West Virginia': 'Northeast',
	Wisconsin: 'Midwest',
	Wyoming: 'High Plains',
}

export const statesScores = {
	Alabama: { precon: 'C', sitecon: 'B' },
	Alaska: { precon: 'B', sitecon: 'C' },
	Arizona: { precon: 'B', sitecon: 'B' },
	Arkansas: { precon: 'A', sitecon: 'B' },
	California: { precon: 'A', sitecon: 'B' },
	Colorado: { precon: 'B', sitecon: 'B' },
	Connecticut: { precon: 'C', sitecon: 'A' },
	Delaware: { precon: 'B', sitecon: 'A' },
	Florida: { precon: 'A', sitecon: 'C' },
	Georgia: { precon: 'B', sitecon: 'B' },
	Hawaii: { precon: 'C', sitecon: 'A' },
	Idaho: { precon: 'B', sitecon: 'B' },
	Illinois: { precon: 'B', sitecon: 'A' },
	Indiana: { precon: 'B', sitecon: 'B' },
	Iowa: { precon: 'B', sitecon: 'B' },
	Kansas: { precon: 'B', sitecon: 'C' },
	Kentucky: { precon: 'B', sitecon: 'C' },
	Louisiana: { precon: 'B', sitecon: 'A' },
	Maine: { precon: 'C', sitecon: 'C' },
	Maryland: { precon: 'C', sitecon: 'B' },
	Massachusetts: { precon: 'B', sitecon: 'B' },
	Michigan: { precon: 'B', sitecon: 'B' },
	Minnesota: { precon: 'B', sitecon: 'B' },
	Mississippi: { precon: 'B', sitecon: 'C' },
	Missouri: { precon: 'B', sitecon: 'B' },
	Montana: { precon: 'B', sitecon: 'B' },
	Nebraska: { precon: 'B', sitecon: 'B' },
	Nevada: { precon: 'B', sitecon: 'B' },
	'New Hampshire': { precon: 'C', sitecon: 'B' },
	'New Jersey': { precon: 'B', sitecon: 'B' },
	'New Mexico': { precon: 'C', sitecon: 'B' },
	'New York': { precon: 'A', sitecon: 'A' },
	'North Carolina': { precon: 'A', sitecon: 'A' },
	'North Dakota': { precon: 'B', sitecon: 'B' },
	Ohio: { precon: 'B', sitecon: 'B' },
	Oklahoma: { precon: 'B', sitecon: 'B' },
	Oregon: { precon: 'B', sitecon: 'B' },
	Pennsylvania: { precon: 'A', sitecon: 'A' },
	'Rhode Island': { precon: 'B', sitecon: 'B' },
	'South Carolina': { precon: 'A', sitecon: 'A' },
	'South Dakota': { precon: 'B', sitecon: 'C' },
	Tennessee: { precon: 'C', sitecon: 'B' },
	Texas: { precon: 'A', sitecon: 'A' },
	Utah: { precon: 'B', sitecon: 'B' },
	Vermont: { precon: 'C', sitecon: 'B' },
	Virginia: { precon: 'A', sitecon: 'B' },
	Washington: { precon: 'A', sitecon: 'A' },
	'West Virginia': { precon: 'C', sitecon: 'C' },
	Wisconsin: { precon: 'B', sitecon: 'C' },
	Wyoming: { precon: 'B', sitecon: 'C' },
}

export const regionsScores = {
	Western: { precon: 'B', sitecon: 'B' },
	'High Plains': { precon: 'B', sitecon: 'C' },
	Southern: { precon: 'B', sitecon: 'B' },
	Southeast: { precon: 'A', sitecon: 'B' },
	Northeast: { precon: 'B', sitecon: 'A' },
	Midwest: { precon: 'B', sitecon: 'B' },
	Alaska: { precon: 'B', sitecon: 'C' },
	Hawaii: { precon: 'C', sitecon: 'B' },
}

export const regionsWording = {
	Western: `As other regions begin to see signs of a slowdown, the West shows renewed strength. The Architecture Billings Index (ABI) and the Producer Price Index (PPI) signal strong profits for general contractors in this region in 2020. While the West will struggle with a labor shortage, projections suggest mild relief in the form of occupational growth. However, the West has room to improve when it comes to safety, with incidents occurring more frequently and leading to more days away from work than in other regions.`,
	'High Plains': `When investigating the health of the construction industry in the High Plains region, it is important to understand how the region differs in size from others. Only 6 percent of all construction workers in the United States live in this region, while every other region claims 17-21 percent of the construction population. With this unique size comes some unique challenges in analyzing the construction climate, and of course, some unique challenges operating in this area.`,
	Southern: `While the data shows proof of historic strength in the South, there are also signs that 2020 will be a year of change. For instance, growth in construction employment tops the charts, but the region continues to suffer from a labor shortage. The South may be a good choice for projects when compared to other regions, but it still won’t be easy to find quality labor. Additionally, spending slowdowns in specific categories throughout 2019 mean 2020 may follow suit.`,
	Southeast: `The Southeast region is the only region in the report to receive an A for its preconstruction risk score. Between the healthiest construction start and spending data, impressive construction employment projections, and anecdotal evidence from experts, this region looks like it will have a great year ahead in 2020. However, while the construction industry continues to expand in the Southeast, there may be some side effects of this growth—the biggest being rising subcontractor default rates.`,
	Northeast: `The Northeast region is somewhat unique: it has more states than any other region in the report, and the data differs significantly between the cluster of smaller states in the region. Overall, the region received a B rating in preconstruction risk, but a handful of states in the region scored lower. Notably, the Northeast region’s safety record is the best in the nation, securing an A rating in site construction risk.`,
	Midwest: `Compared to other regions in the U.S., the Midwest region had very similar results across state lines—making it one of the most homogenous regions for construction risk. For most of the metrics we looked at, the Midwest states all landed somewhere in the third quartile of results. Overall, there appears to be a slowdown both in construction spending and job growth for this region.`,
	Alaska: `Alaska's economy has been in recession for the past three years. Economists are unsure when the recession will end and what that turnaround will look like, but 2020 looks like it may be a turning point. The industries most affected by the recession (including oil, construction, and professional services) are all at least stable or on the rebound in 2019.`,
	Hawaii: `Analyzing a state like Hawaii (which has a relatively smaller construction industry than the rest of the nation) makes it easier to estimate the direction the industry is headed. While overall, Hawaii’s economy seems to be slowing, construction looks to be the state’s saving grace. Since our scores are relative within states, Hawaii received a C rating in preconstruction risk—but the future generally looks bright.`,
}
