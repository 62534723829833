import * as React from 'react'
import styled from 'styled-components'

import { statesToRegions, statesScores, regionsScores } from '../constants'
import Graphs from './graphs'

const Wrapper = styled.div`
	width: 100%;
`
const GraphsSection = styled.div`
	display: flex;
	justify-content: center;
`
const GraphsSpacer = styled.div`
	flex: 0 0 50px;
`
const SingleGraphSpacer = styled.div`
	margin-bottom: 40px;
`

const ResultsSection: React.FC<{
	state: string
}> = ({ state }) => {
	const region = statesToRegions[state]
	const regionStates = Object.keys(statesToRegions).filter(
		s => statesToRegions[s] === region
	)
	const stateScores = statesScores[state]
	const regionScores = regionsScores[region]
	const singleStateRegion = regionStates && regionStates.length === 1

	return (
		<Wrapper>
			<GraphsSection>
				<Graphs
					title={state}
					preconScore={stateScores.precon}
					siteconScore={stateScores.sitecon}
				/>
				{!singleStateRegion && (
					<>
						<GraphsSpacer />
						<Graphs
							title={region}
							preconScore={regionScores.precon}
							siteconScore={regionScores.sitecon}
							postText={regionStates.join(', ')}
						/>
					</>
				)}
			</GraphsSection>
			{singleStateRegion && <SingleGraphSpacer />}
		</Wrapper>
	)
}

export default ResultsSection
