import * as React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { Hero, Button } from 'views/components'
import { mobile } from 'shared/media-queries'
import { lightBlue } from 'shared/colors'
import backgroundVideoMp4 from './video.mp4'
import backgroundVideoWebm from './video.webm'
import { showRiskReportModal } from 'components/modal-manager'

const StyledButton = styled(Button)`
	background-color: ${lightBlue};
	margin-right: 10px;
	${mobile} {
		margin-right: 0;
	}
`
const OutlineButton = styled(Button)`
	background: none;
	border: 1px solid ${lightBlue};
`
const SubHeading = styled.div`
	max-width: 575px;
`
const LinkWrapper = styled.div`
	${mobile} {
		display: none;
	}
`

const Header: React.FC = () => (
	<Hero
		backgroundVideo={{
			webm: backgroundVideoWebm,
			mp4: backgroundVideoMp4,
		}}
		heading="2020 U.S. Construction Risk Outlook"
		subHeading={
			<SubHeading>
				Is your organization prepared for the year ahead? Take a critical look
				at the construction risk landscape in this interactive report.
			</SubHeading>
		}
		wordingWidth="575px"
		CTAs={[
			<div key="Download the report">
				<StyledButton
					label="Download the report"
					onClick={showRiskReportModal}
				/>
			</div>,
			<LinkWrapper key="scrollTo link">
				<Link to="/risk-trends?scrollTo=riskScore">
					<OutlineButton label="Check your region" />
				</Link>
			</LinkWrapper>,
		]}
	/>
)

export default Header
