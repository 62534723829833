import * as React from 'react'

import { H2, FAQTable, Spacer } from 'views/components'
import faqs from './faqs'
import { lightBlue } from 'shared/colors'

const FAQsSection = () => (
	<>
		<H2 textAlign="center">FAQs</H2>
		<Spacer size={40} />
		<FAQTable faqs={faqs} borderColor={lightBlue} numbered />
	</>
)

export default FAQsSection
