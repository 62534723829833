import * as React from 'react'

import useScrollTo from 'hooks/use-scroll-to'
import Layout from 'components/layout'
import Header from './header'
import { SectionSpacer, ContentWrapper, Footer } from 'views/components'
import Introduction from './introduction'
import NationalFinding from './national-finding'
import LocalTrends from './local-trends'
import RiskScore from './risk-score'
import FAQs from './faq-section'
import { lightBlue } from 'shared/colors'

const RiskTrends = () => {
	const riskScoreRef = React.useRef(null)

	useScrollTo({
		keysToRefs: {
			riskScore: riskScoreRef,
		},
		offset: -60,
		ignoreLastScrolledTo: true,
	})

	return (
		<Layout
			title="2020 U.S. Construction Risk Outlook | BuildingConnected"
			description="Is your organization prepared for the year ahead? Take a critical look at the construction risk landscape in this interactive report."
		>
			<Header />
			<ContentWrapper>
				<SectionSpacer />
				<Introduction />
				<SectionSpacer />
			</ContentWrapper>
			<NationalFinding />
			<ContentWrapper>
				<SectionSpacer />
				<LocalTrends />
				<SectionSpacer />
			</ContentWrapper>
			<RiskScore forwardedRef={riskScoreRef} />
			<SectionSpacer />
			<ContentWrapper>
				<FAQs />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Start reducing your risk today with TradeTapp."
				ctaColor={lightBlue}
				demoButtonPadding="60px"
			/>
		</Layout>
	)
}

export default RiskTrends
