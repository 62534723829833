import * as React from 'react'
import styled from 'styled-components'

import { SectionInfo, Spacer } from 'views/components'
import infoSections from './sections'
import { tablet, mobile } from 'shared/media-queries'

const SectionInfoWrapper = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
`
const FlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	${tablet} {
		flex-direction: column;
	}
`
const FlexChild = styled.div`
	flex: 0 0 30%;
	text-align: center;
	${tablet} {
		display: flex;
		align-items: center;
	}
	${mobile} {
		flex-direction: column;
	}
`
const Image = styled.div`
	${tablet} {
		flex: 0 0 264px;
		padding: 10px;
		text-align: right;

		svg {
			height: 150px;
		}
	}
	${mobile} {
		flex: 0 0 auto;

		svg {
			height: 100px;
		}
	}
`
const Text = styled.div`
	font-size: 18px;
	font-weight: 600;
	${tablet} {
		text-align: left;
	}
	${mobile} {
		text-align: center;
	}
`
const ContainerSpacer = styled(Spacer)`
	${mobile} {
		height: 30px;
	}
`
const ChildSpacer = styled(Spacer)`
	${mobile} {
		display: none;
	}
`

const LocalTrends = () => (
	<>
		<SectionInfoWrapper>
			<SectionInfo
				title="Local Trends"
				subtitle="Job Growth by State"
				info="Our analysis uncovered a significant difference between national and local trends. Here’s an example: while the U.S. is primed for job growth, many states are challenged by labor shortages. So, which states will experience job growth in 2020?"
			/>
		</SectionInfoWrapper>
		<ContainerSpacer size={60} />
		<FlexContainer>
			{infoSections.map(({ image, text }) => (
				<FlexChild key={text}>
					<Image>{image}</Image>
					<ChildSpacer size={20} />
					<Text>{text}</Text>
					<Spacer size={20} />
				</FlexChild>
			))}
		</FlexContainer>
	</>
)

export default LocalTrends
