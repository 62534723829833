import * as React from 'react'
import styled from 'styled-components'

import { tablet, mobile } from 'shared/media-queries'

import {
	ContentWrapper,
	SectionSpacer,
	InfoAndImage,
	GrayBackground,
} from 'views/components'
import backgroundImageURI from './background-image'
import SDIImage from './sdi-image.svg'

const BackgroundImage = styled.div`
	background: url(${backgroundImageURI}) no-repeat top center;
	background-size: contain;
`
const InfoAndImageWrapper = styled.div`
	${tablet} {
		text-align: center;
		svg {
			height: auto;
			max-width: 400px;
		}
	}
	${mobile} {
		svg {
			max-width: 300px;
		}
	}
`

const NationalFinding = () => (
	<GrayBackground>
		<BackgroundImage>
			<SectionSpacer />
			<ContentWrapper>
				<InfoAndImageWrapper>
					<InfoAndImage
						imageOnRight
						title="NATIONAL FINDING"
						subtitle="Subcontractor Default"
						info="Subcontractor default claims are on the rise—both in size and frequency. The market has responded by increasing the number of SDI products available to general contractors and owners."
						wordingWidth="550px"
						image={<SDIImage width="100%" />}
						spacerWidths={['100px', '50px']}
					/>
				</InfoAndImageWrapper>
			</ContentWrapper>
			<SectionSpacer />
		</BackgroundImage>
	</GrayBackground>
)

export default NationalFinding
