import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "risk-trends/risk-score/summary/image.jpg" }
				) {
					childImageSharp {
						fixed(height: 175) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fixed={data.fileName.childImageSharp.fixed}
			alt="risk score summary image"
		/>
	)
}

export default Image
