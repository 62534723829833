import * as React from 'react'
import styled from 'styled-components'

import { SectionInfo, Spacer } from 'views/components'
import MapNetwork from 'icons/map-network.svg'

const Wrapper = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
`

const Introduction = () => (
	<Wrapper>
		<SectionInfo
			title="INTRODUCTION"
			subtitle="What inspired this report?"
			info="We believe that the entire construction industry benefits when we work together. That’s why we created this report: to share valuable insights with general contractors and owners across the nation, helping everyone make smarter decisions about risk."
		/>
		<Spacer size={40} />
		<MapNetwork />
	</Wrapper>
)

export default Introduction
