import * as React from 'react'

import Arizonhat from './img/arizonhat.svg'
import Californibulb from './img/californibulb.svg'
import Floridalator from './img/floridalator.svg'

export default [
	{
		image: <Arizonhat />,
		text: `After a 10.8% rise in construction managers over the past two years, Arizona will continue to see the most growth for this role in 2020.`,
	},
	{
		image: <Californibulb />,
		text: `California averages 6,070 openings for electricians annually, but in 2020, the state is only expected to grow by 3,700 electricians (5.2%).`,
	},
	{
		image: <Floridalator />,
		text: `Florida’s number of estimators is projected to grow by 1,400 (9.2%) in 2020, which will not be enough to cover the 2,240 vacancies averaged annually.`,
	},
]
