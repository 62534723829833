import * as React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'

import { statesToRegions } from './constants'
import { grayText, gray20 } from 'shared/colors'

const StyledSelect = styled(ReactSelect)`
	margin-top: 20px;
	width: 80%;
	max-width: 800px;
`

const StateSelect: React.FC<{
	onChange: (arg0: { value: string }) => any
}> = ({ onChange }) => (
	<StyledSelect
		options={Object.keys(statesToRegions).map(state => ({
			value: state,
			label: state,
		}))}
		placeholder="Enter the main state you work in"
		onChange={onChange}
		styles={{
			option: (base, { isFocused }) => ({
				...base,
				fontSize: '16px',
				color: grayText,
				backgroundColor: isFocused ? gray20 : 'default',
			}),
		}}
	/>
)

export default StateSelect
